import React, { useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Creatable from 'react-select/creatable'
import { CARRIER } from './'


interface RowProps {
    addNewGildCodes?: any
    gildClassesMap: any
    gildMenuItems: any
    updateClassCodesResp?: any
    // Coterie keys
    active?: boolean
    classCode: string
    description: string
    gildCode?: string[]
    inAppetite: boolean
    isTargeted: boolean
    metadata?: any
    products?: any
    readOnly?: boolean
}


const Product = (props: any) => {
    const { checked, code, color, onChange, product, readOnly } = props
    const lower = product.toLowerCase().replace(' ', '-')
    return (
        <>
            <Checkbox 
                id={`${lower}-${code}`}
                checked={checked}
                color={color}
                onChange={(e, checked) => onChange(e, checked, lower)} 
                disabled={readOnly}
                />
            <label htmlFor={`${lower}-${code}`}>{product}</label>
        </>
    )
}


const Row = (props: RowProps) => {
    const { addNewGildCodes, classCode, description, gildClassesMap, gildMenuItems, updateClassCodesResp, readOnly } = props
    const [active, setActive] = useState(props.active || false)
    const [gildCode, setGildCode] = useState(props.gildCode || [])
    const [newGildCode, setNewGildCode] = useState('')
    const [products, setProducts] = useState(props.products)

    const descriptionStyle: any = {}
    if (!active) {
        descriptionStyle.color = 'grey'
        descriptionStyle.fontStyle = 'italic'
    }

    const selectStyle = {
        multiValueLabel: (base: any) => ({
            ...base,
            fontSize: 14
        })
    }

    const onActiveChange = (e: any, checked: boolean) => {
        updateClassCodesResp(classCode, {active: checked})
        setActive(checked)
    }

    const onGildCodeChange = (sel?: any) => {
        // Auto select active
        if (!gildCode.length && sel.length) {
            // If selecting a description for the first time, automatically check active
            updateClassCodesResp(classCode, {active: true})
            setActive(true)
        }
        // Check if there are new codes
        if (newGildCode) {
            const newVal = addNewGildCodes(sel.map((s: any) => s.value))
            updateClassCodesResp(classCode, {gildCode: newVal})
            setGildCode(newVal)
            return
        }
        // Set new value
        const newVal = sel.map((s: any) => s.value)
        updateClassCodesResp(classCode,{gildCode: newVal})
        setGildCode(newVal)
    }

    const onProductsChange = (e: any, checked: boolean, product: string) => {
        updateClassCodesResp(classCode, {products: { ...products, [product]: checked }})
        setProducts({ ...products, [product]: checked })
    }

    const inAppetite = true
    
    return (
        <TableRow key={classCode} sx={{backgroundColor: inAppetite ? '#fff' : '#2eb4b608' }}>
            <TableCell>
                <Checkbox id={`active-${classCode}`} color={inAppetite ? 'primary' : 'secondary'} checked={active} onChange={onActiveChange} disabled={readOnly} />
            </TableCell>
            <TableCell>{classCode}</TableCell>
            <TableCell><label htmlFor={`active-${classCode}`} style={descriptionStyle}>{description}</label></TableCell>
            <TableCell>
                <Creatable
                    isClearable
                    isMulti
                    isDisabled={readOnly}
                    options={gildMenuItems}
                    onChange={(sel) => onGildCodeChange(sel)}
                    onInputChange={(newVal) => setNewGildCode(newVal)}
                    styles={selectStyle}
                    value={gildCode.filter(c => !!c).map(c => ({ value: c, label: gildClassesMap[c] }))} />
            </TableCell>
            <TableCell>
                <Product color={inAppetite ? 'primary' : 'secondary'} checked={products?.bop} code={classCode} onChange={onProductsChange} product="BOP" readOnly={readOnly} />
                <Product color={inAppetite ? 'primary' : 'secondary'} checked={products?.pl} code={classCode} onChange={onProductsChange} product="PL" readOnly={readOnly} />
                <Product color={inAppetite ? 'primary' : 'secondary'} checked={products?.gl} code={classCode} onChange={onProductsChange} product="GL" readOnly={readOnly} />
                <Product color={inAppetite ? 'primary' : 'secondary'} checked={products?.wc} code={classCode} onChange={onProductsChange} product="WC" readOnly={readOnly} />
                <Product color={inAppetite ? 'primary' : 'secondary'} checked={products?.['commercial-auto']} code={classCode} onChange={onProductsChange} product="Commercial Auto" readOnly={readOnly} />
            </TableCell>
        </TableRow>
    )
}

export default Row