import { useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { Admin, CustomRoutes, LinearProgressProps, Resource } from 'react-admin'
import { Route } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import LockIcon from '@mui/icons-material/Lock'

import authProvider from './auth'
import Biberk from './Biberk'
import Chubb from './Chubb'
import Coterie from './Coterie'
import Dashboard from './Dashboard'
import Employers from './Employers'
import { env } from './env'
import Gild from './Gild'
import GreatAmerican from './GreatAmerican'
import Hartford from './Hartford'
import Hiscox from './Hiscox'
import Layout from './Layout'
import Progessive from './Progressive'
import theme from './theme'
import { makeHeaders } from './util'


let authenticateInterval;
let listener = false

let gildCodesResp: any
// let productsResp: any

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}


const Manager = () => {
    const [browserTabIsActive, setBrowserTabIsActive] = useState(true)
    const [gildCodes, setGildCodes] = useState<any[]>([])
    const [loadingColor, setLoadingColor] = useState<LinearProgressProps['color']>('secondary')
    const [locked, setLocked] = useState(false)
    const [lockedInfo, setLockedInfo] = useState<any>({})
    const [token, setToken] = useState()
    const [readOnly, setReadOnly] = useState(false)
    const authenticate = () => Auth.currentSession().then((resp: any) => {
        try {
            console.log('Reauthenticating...')
            const accessJwt = resp.getAccessToken().getJwtToken()
            setToken(accessJwt)
            console.log('Success')
        } catch {
            authProvider.logout()
        }
    })

    useEffect(() => {
        // Set readOnly to true if user is in the agent group
        authProvider.getPermissions().then(res => { 
            console.log('auth', res, 'is read only:', env.agentUserGroup, res.indexOf(env.agentUserGroup) >= 0)
            setReadOnly(res.indexOf(env.agentUserGroup) >= 0) // if anyone is added to both admin and agent groups, they will only be able to get the read-only version.
        })
    }, [])

    useEffect(() => {
        // Set "active tab" listener
        if (!listener) {
            listener = true
            document.addEventListener('visibilitychange', (event) => {
                setBrowserTabIsActive(!!!document.hidden)
            })
        }
        // Re-authenticate every 5 minutes (this prevents it from expiring if the page is never reloaded)
        authenticateInterval = setInterval(() => {
            authenticate()
        }, 1000 * 60 * 5) 
        authenticate()
    }, [])

    useEffect(() => {
        if (token) {
            const url = `${env.facadeBaseUrl}/appetite/carrier/gild/class_codes`
            const headers = makeHeaders(token)
            axios({
                method: 'get',
                url,
                headers
            })
            .then((response: AxiosResponse<any>) => {
                if (response.data) {
                    gildCodesResp = response.data['class_codes']
                    updateGildCodeOptions()
                }
            }).catch((err: any) => {
                console.log('useEffect.catch', err)
                if (err?.response?.status === 403) {
                    setLocked(true)
                    axios({
                        url: `${env.facadeBaseUrl}/appetite/lockfile`,
                        method: 'get',
                        headers
                    }).then(resp => {
                        setLockedInfo(resp.data)
                    })
                } else {
                    authProvider.logout()
                }
                setLoadingColor('error')
            })
        }
    }, [token])

    const addNewGildCodes = (codes: string[]) => {
        if (!token) {
            console.error('No authorization token present. Aborting addNewGildCodes...')
            return
        }
        // Recieves an array of the currently selected values; any existing values will be GildIDs
        const newVal = codes.map((code: string) => {
            if (!gildCodesResp[code]) {
                const newId = `${code.slice(0, 14).replaceAll(' ', '-', )}-${uuidv4().slice(0,6).replaceAll('-','')}`
                gildCodesResp[newId] = {
                    active: true,
                    Description: code,
                    FullDescription: code,
                    GildId: newId,
                    Keywords: [],
                    carrier_order: {
                        'commerical-auto': ['progressive']
                    }
                }
                return newId
            }
            return code
        })
        postGildCodes()
        updateGildCodeOptions()
        return newVal
    }

    const deleteGildCode = (code: string) => {
        if (!token) {
            console.error('No authorization token present. Aborting deleteGildCodes...')
            return
        }
        delete gildCodesResp[code]
        postGildCodes()
        updateGildCodeOptions()
    }

    const postGildCodes = () => {
        if (!token || readOnly) {
            return
        }
        const url = `${env.facadeBaseUrl}/appetite/carrier/gild/class_codes`
        const headers = makeHeaders(token)
        axios({
            method: 'post',
            url,
            data: { class_codes: gildCodesResp },
            headers
        })
        .then((resp => {
            
        }))
        .catch(err => {
            
        })
    }

    const updateGildCodeOptions = () => {
        const codes = Object.values(gildCodesResp).map((code: any) => (
            {
                code: code['GildId'],
                description: code['BuildingType'] ? `${code['Description']} (${code['BuildingType']})` : code['Description']
            }
        ))
        codes.sort((a: any, b: any) => a['description'] > b['description'] ? 1 : -1)
        setGildCodes(codes)
    }

    return (
        <>
            <Admin
                authProvider={authProvider}
                layout={Layout}
                dashboard={Dashboard} 
                theme={theme}>
                <Resource name="partners" options={{ label: 'Partners' }} />
                <CustomRoutes>
                    <Route path="/chubb" element={gildCodes.length ? 
                        <Chubb 
                            /* Gild Codes */
                            addNewGildCodes={addNewGildCodes} 
                            gildClasses={gildCodes}
                            /* Other */
                            isActive={browserTabIsActive}
                            token={token} 
                            readOnly={readOnly} 
                            /> : 
                        <LinearProgress color={loadingColor} />} />
                    <Route path="/coterie" element={gildCodes.length ? 
                        <Coterie 
                            /* Gild Codes */
                            addNewGildCodes={addNewGildCodes} 
                            gildClasses={gildCodes} 
                            gildCodesResp={gildCodesResp} 
                            /* Other */
                            isActive={browserTabIsActive}
                            token={token} 
                            readOnly={readOnly}
                            /> : 
                        <LinearProgress color={loadingColor} />} />
                    <Route path="/employers" element={gildCodes.length ? 
                        <Employers 
                            /* Gild Codes */
                            addNewGildCodes={addNewGildCodes} 
                            gildClasses={gildCodes} 
                            gildCodesResp={gildCodesResp} 
                            /* Other */
                            isActive={browserTabIsActive}
                            token={token} 
                            readOnly={readOnly}
                            /> : 
                        <LinearProgress color={loadingColor} />} />
                    <Route path="/gild" element={
                        <Gild 
                            /* Gild Codes */
                            deleteGildCode={deleteGildCode}
                            /* Other */
                            isActive={browserTabIsActive}
                            token={token} 
                            readOnly={readOnly}
                            />} 
                    />
                    <Route path="/great-american" element={
                        <GreatAmerican 
                            /* Gild Codes */
                            addNewGildCodes={addNewGildCodes} 
                            gildClasses={gildCodes} 
                            gildCodesResp={gildCodesResp} 
                            /* Other */
                            isActive={browserTabIsActive}
                            token={token} 
                            readOnly={readOnly}
                            />} 
                    />

                    <Route path="/hartford" element={
                        <Hartford 
                            addNewGildCodes={addNewGildCodes} 
                            gildClasses={gildCodes} 
                            gildCodesResp={gildCodesResp} 
                            isActive={browserTabIsActive}
                            token={token} 
                            readOnly={readOnly}
                            />} 
                    />
                    <Route path="/hiscox" element={
                        <Hiscox 
                            addNewGildCodes={addNewGildCodes} 
                            gildClasses={gildCodes} 
                            gildCodesResp={gildCodesResp} 
                            isActive={browserTabIsActive}
                            token={token} 
                            readOnly={readOnly}
                            />} 
                    />
                    <Route path="/biberk" element={
                        <Biberk 
                            addNewGildCodes={addNewGildCodes} 
                            gildClasses={gildCodes} 
                            gildCodesResp={gildCodesResp} 
                            isActive={browserTabIsActive}
                            token={token} />} 
                    />
                    <Route path="/progressive" element={<Progessive />} />
                </CustomRoutes>
            </Admin>
            <Modal
                open={locked && !readOnly}
                onClose={() => {}}>
                <Box sx={modalStyle}>
                    <LockIcon sx={{float: 'left', marginRight: '10px'}} />
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Editing is Currently Locked.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {lockedInfo?.user || 'Another user'} is currently making changes. The product map manager
                        will be available again after the current user has been inactive
                        for {Math.round(((20 * 60) - (lockedInfo?.diff || 0)) / 60)} minutes.
                    </Typography>
                </Box>
            </Modal>
            <img className="gild-city" src={`${env.deployedBuildPath}front-city.png`} />
        </>
    )
//   }
//   return <></>
}

export default Manager