import { Menu } from 'react-admin';


const CustomMenu = () => (
    <Menu>
        {/* <Menu.DashboardItem /> */}
        <Menu.Item to="/gild" primaryText="Gild" />
        <Menu.Item to="/chubb" primaryText="Chubb" />
        <Menu.Item to="/coterie" primaryText="Coterie" />
        <Menu.Item to="/employers" primaryText="Employers" />
        <Menu.Item to="/great-american" primaryText="Great American" />
        <Menu.Item to="/hartford" primaryText="Hartford" />
        <Menu.Item to="/hiscox" primaryText="Hiscox" />
        <hr style={{width:'85%'}} />
        <Menu.Item to="/biberk" primaryText="Biberk" />
        <Menu.Item to="/progressive" primaryText="Progressive" />
    </Menu>
);

export default CustomMenu