import { useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Creatable from 'react-select/creatable'


interface RowProps {
    addNewGildCodes?: any
    gildClassesMap: any
    gildMenuItems: any
    update?: any
    // Great American keys
    active?: boolean
    classCode: string
    Description: string
    exclusions?: string
    gildCode?: string[]
    inAppetite: boolean
    isTargeted: boolean
    manual: any
    metadata?: any
    products: any
}


const exclusionsStyle = {
    background: 'rgb(224, 224, 224)',
    textAlign: 'center',
    color: '#333',
    fontStyle: 'italic',
    borderBottom: '1px solid #AAA',
    padding: '5px !important'
}


const Product = (props: any) => {
    const { checked, code, color, onChange, product } = props
    const lower = product.toLowerCase().replace(' ', '-')
    return (
        <>
            <Checkbox 
                id={`${lower}-${code}`}
                checked={checked}
                color={color}
                onChange={(e, checked) => onChange(e, checked, lower)} />
            <label htmlFor={`${lower}-${code}`}>{product}</label>
        </>
    )
}


const Row = (props: RowProps) => {
    const { addNewGildCodes, classCode, Description, exclusions, gildClassesMap, gildMenuItems, update } = props
    const [active, setActive] = useState(props.active || false)
    const [gildCode, setGildCode] = useState(props.gildCode || [])
    const [manual, setManual] = useState(props.manual)
    const [newGildCode, setNewGildCode] = useState('')
    const [products, setProducts] = useState(props.products)

    const descriptionStyle: any = {}
    if (!active) {
        descriptionStyle.color = 'grey'
        descriptionStyle.fontStyle = 'italic'
    }

    const selectStyle = {
        multiValueLabel: (base: any) => ({
            ...base,
            fontSize: 14
        })
    }

    const onActiveChange = (e: any, checked: boolean) => {
        update(classCode, {active: checked})
        setActive(checked)
    }

    const onGildCodeChange = (sel?: any) => {
        // Auto select active
        if (!gildCode.length && sel.length) {
            // If selecting a description for the first time, automatically check active
            update(classCode, {active: true})
            setActive(true)
        }
        // Check if there are new codes
        if (newGildCode) {
            const newVal = addNewGildCodes(sel.map((s: any) => s.value))
            update(classCode, {gildCode: newVal})
            setGildCode(newVal)
            return
        }
        // Set new value
        const newVal = sel.map((s: any) => s.value)
        update(classCode, {gildCode: newVal})
        setGildCode(newVal)
    }

    const onManualChange = (e: any, checked: boolean, product: string) => {
        update(classCode, {manual: { ...manual, [product]: checked }})
        setManual({ ...manual, [product]: checked })
    }

    // const onProductsChange = (e: any, checked: boolean, product: string) => {
    //     update(classCode, {products: { ...products, [product]: checked }})
    //     setProducts({ ...products, [product]: checked })
    // }
    
    return (
        <>
            <TableRow key={classCode}>
                <TableCell>
                    <Checkbox id={`active-${classCode}`}  checked={active} onChange={onActiveChange} />
                </TableCell>
                <TableCell><label htmlFor={`active-${classCode}`} style={descriptionStyle}>{Description}</label></TableCell>
                <TableCell>
                    <Creatable
                        isClearable
                        isMulti
                        options={gildMenuItems}
                        onChange={(sel) => onGildCodeChange(sel)}
                        onInputChange={(newVal) => setNewGildCode(newVal)}
                        styles={selectStyle}
                        value={gildCode.filter(c => !!c).map(c => ({ value: c, label: gildClassesMap[c] }))} />
                </TableCell>
                {/* <TableCell>
                    <Product color="primary" checked={products?.bop} code={classCode} onChange={onProductsChange} product="BOP" />
                    <Product color="primary" checked={products?.pl} code={classCode} onChange={onProductsChange} product="PL" />
                    <Product color="primary" checked={products?.gl} code={classCode} onChange={onProductsChange} product="GL" />
                    <Product color="primary" checked={products?.wc} code={classCode} onChange={onProductsChange} product="WC" />
                    <Product color="primary" checked={products?.['commercial-auto']} code={classCode} onChange={onProductsChange} product="Commercial Auto" />
                </TableCell> */}
                <TableCell>
                    <Product color="secondary" checked={manual?.bop} code={classCode} onChange={onManualChange} product="BOP" />
                    <Product color="secondary" checked={manual?.pl} code={classCode} onChange={onManualChange} product="PL" />
                    <Product color="secondary" checked={manual?.gl} code={classCode} onChange={onManualChange} product="GL" />
                    <Product color="secondary" checked={manual?.wc} code={classCode} onChange={onManualChange} product="WC" />
                    <Product color="secondary" checked={manual?.['commercial-auto']} code={classCode} onChange={onManualChange} product="Commercial Auto" />
                </TableCell>
            </TableRow>
            {exclusions && (
                <TableRow key={`${classCode}-exclusions`}>
                    <TableCell colSpan={5} sx={exclusionsStyle}>{exclusions}</TableCell>
                </TableRow>
            )}
        </>
    )
}

export default Row