import Checkbox from '@mui/material/Checkbox'


const Product = (props: any) => {
    const { checked, code, color, onChange, product, readOnly } = props
    const lower = product.toLowerCase().replace(' ', '-')
    return (
        <>
            <Checkbox 
                id={`${lower}-${code}`}
                checked={checked}
                color={color}
                onChange={(e, checked) => onChange(e, checked, lower)} 
                disabled={readOnly}
                /> 
            <label htmlFor={`${lower}-${code}`}>{product}</label>
        </>
    )
}
export default Product